import { LoyaltyInfo } from "src/entities/marketing/Loyalty"

const data: LoyaltyInfo = {
    bonusSystem: {
        max: 0.0,
        wellcomeBonus: 0.0,
        hasTransitionActive: true,
        transitionCondition: [{ id: 1, fromAmount: 0, groupId: 0, event: '' }]
    },
    discountSystem: {
        hasTransitionActive: true,
        transitionCondition: [{ id: 1, fromAmount: 0.0, groupId: 0, event: '' }]
    }
}

const loyaltyInfoReducer = (state: LoyaltyInfo = data, action) => {
    switch (action.type) {       
        case 'TRANSITION_CONDITION_BONUS_ADD':
            return {
                bonusSystem: { ...state.bonusSystem, transitionCondition: [...state.bonusSystem.transitionCondition, action.payload] },
                discountSystem: { ...state.discountSystem }
            }
        case 'TRANSITION_CONDITION_BONUS_REMOVE':
            return {
                bonusSystem: { ...state.bonusSystem, transitionCondition: [...(state.bonusSystem.transitionCondition.filter(x => x.id !== action.payload.id))] },
                discountSystem: { ...state.discountSystem }
            }
        case 'TRANSITION_CONDITION_DISCOUNT_ADD':
            return {
                discountSystem: { ...state.discountSystem, transitionCondition: [...state.discountSystem.transitionCondition, action.payload] },
                bonusSystem: { ...state.bonusSystem }
            }
        case 'TRANSITION_CONDITION_DISCOUNT_REMOVE':
            return {
                discountSystem: { ...state.discountSystem, transitionCondition: [...(state.discountSystem.transitionCondition.filter(x => x.id !== action.payload.id))] },
                bonusSystem: { ...state.bonusSystem }
            }
        case 'BONUS_SYSTEM_SET_MAX_PERCENT':
            return { ...state, bonusSystem: { ...state.bonusSystem, max: action.payload } }

        case 'BONUS_SYSTEM_SET_WELLCOME_BONUS':
            return { ...state, bonusSystem: { ...state.bonusSystem, wellcomeBonus: action.payload } }

        case 'BONUS_SYSTEM_SET_HAS_TRANSITION':
            return { ...state, bonusSystem: { ...state.bonusSystem, hasTransitionActive: action.payload } }

        case 'DISCOUNT_SYSTEM_SET_HAS_TRANSITION':
            return { ...state, discountSystem: { ...state.discountSystem, hasTransitionActive: action.payload } }

        case 'SET_LOYALTY_INFO':
            return { ...state, ...action.payload  }
        default:
            return state
    }
}

export default loyaltyInfoReducer