import { RewardTypes } from "src/entities/marketing/RewardTypes"

const data: RewardTypes[] = []
const loyaltyRewardTypesReducer = (state: RewardTypes[] = data, action) => {
    switch (action.type) {
        case "LOYALTY_REWARDY_TYPES":         
            return [...state, ...action.payload]
        default:
            return state
    }
}

export default loyaltyRewardTypesReducer