import DropDownOptions from "src/entities/dropDownOptions/DropDownOptions"

const dropDownOptionsReducer = (state: DropDownOptions[] = [], action) => {
    switch (action.type) {
        case 'INIT_DROP_DOWN_OPTIONS':
            return [...action.payload]

        default:
            return state
    }
}

export default dropDownOptionsReducer