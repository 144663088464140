import { StackingOption } from "src/entities/marketing/StackingOption"

const data: StackingOption[] = []
const loyaltyStackingOptionsReducer = (state: StackingOption[] = data, action) => {
    switch (action.type) {
        case "LOYALTY_STACKING_OPTIONS":         
            return [...action.payload]
        default:
            return state
    }
}

export default loyaltyStackingOptionsReducer