import { Category } from "src/entities/category/Category" 
import { v4 as guid } from 'uuid'

const data: Category = {
    id: guid(),
    categoryName: "",
    parentId: null,
    imagePath: null,
    imageData: null,
    contentType: null,
    color: "#000000",
    isActive: true,
    sortPriority: 0,
    items: []
}
const categoryReducer = (state: Category = data, action) => {
    switch (action.type) {
        case 'SET_CATEGORY':
            return {...action.payload}

        default:
            return state
    }
}

export default categoryReducer