import { CustomerGroup, Option } from "src/entities/customerGroups/CustomerGroups"

const data: Option[] = []
const customerGroupReducer = (state: Option[] = data, action) => {
    switch (action.type) {
        case 'SET_CUSTOMER_GROUP':
            return [...action.payload]    
        default:
            return state
    }
}

export default customerGroupReducer