import { CategoryProduct, Promotion } from "src/entities/marketing/Promotion"
import { v4 as guid } from 'uuid'

const promo: Promotion = {
  id: null,
  name: "",
  startDate: null,
  endDate: null,
  toAccureBonusesActive: false,
  promotionAutomaticallyActive: false,
  condition: {
    id: guid(),
    categoryProducts: [
      {
        id: guid(),
        itemId: 1,
        itemType: "dish",
        howMany: 0,
        howMuch: 0,
        orderNo: 1
      }
    ],
    stackingId: 1,
    promotionDays: [1, 1, 1, 1, 1, 1, 1],
    promotionHours: [
      {
        id: guid(),
        startTime: '00:00',
        endTime: '23:59'
      }
    ],
    preferredCustomerId: 1,
    customerGroupsId: [{ id: guid(), value: 1, orderNo: 1 }],
    isExactly: true
  },
  reward: {
    rewardTypeId: 1,
    resultId: 1,
    rewardItem: [
      {
        id: guid(),
        itemId: '',
        itemType: "dish",
        toppingsId: []
      }
    ],
    numberBonuses: 0,
    condition: 0,
    discountRate: null,
    fixedAmount: 0,
    fixedCost: 0,
    bonusClaimTime: 1,
    rewardTerm: 1,
    rewardQuantity: 1,
    specialPrice: null,
    rewardIssueTerm: 1,
    flatDiscount: null
  }
}

const promotionInfoReducer = (state: Promotion = promo, action) => {
  switch (action.type) {
    case 'RESET_PROMOTION':
      return { ...promo }

    case 'INIT_PROMOTION':
      return { ...action.payload }

    case 'ADD_CONDITION_CATEGORY_PRODUCT':
      return { ...state, condition: { ...state.condition, categoryProducts: [...state.condition.categoryProducts, action.payload] } }

    case 'SET_PROMOTION_ID':
      return { ...state, id: action.payload }

    case 'SET_PROMOTION_NAME':
      return { ...state, name: action.payload }

    case 'SET_PROMOTION_START_DATE':
      return { ...state, startDate: action.payload }

    case 'SET_PROMOTION_END_DATE':
      return { ...state, endDate: action.payload }

    case 'SET_PROMOTION_TO_ACCURE_BONUSES':
      return { ...state, toAccureBonusesActive: action.payload }

    case 'SET_PROMOTION_AUTOMATICALLY_ACTIVE':
      return { ...state, promotionAutomaticallyActive: action.payload }

    case 'REMOVE_CONDITION_CATEGORY_PRODUCT':
      return { ...state, condition: { ...state.condition, categoryProducts: [...(state.condition.categoryProducts.filter(x => x.id !== action.payload.id))] } }

    case 'CONDITION_CATEGORY_PRODUCT_UPDATE':
      {
        const updatedCondition = state.condition.categoryProducts.find(x => x.id === action.payload.id)

        if (updatedCondition) {
          updatedCondition.howMany = action.payload.howMany
          updatedCondition.howMuch = action.payload.howMuch
          updatedCondition.itemId = action.payload.itemId
          updatedCondition.itemType = action.payload.itemType
          updatedCondition.orderNo = action.payload.orderNo
          return { ...state, condition: { ...state.condition, categoryProducts: [...(state.condition.categoryProducts.filter(x => x.id !== action.payload.id)), updatedCondition] } }
        } else {
          const maxValue = state.condition.categoryProducts.reduce((max, obj) => (obj.orderNo > max ? obj.orderNo : max), state.condition.categoryProducts[0].orderNo)
          const newCondition: CategoryProduct = action.payload
          newCondition.orderNo = maxValue + 1
          return { ...state, condition: { ...state.condition, categoryProducts: [...state.condition.categoryProducts, action.payload] } }
        }
      }

    case 'PROMOTION_CONDITION_UPDATE':
      {
        const updatedCondition = state.condition
        if (updatedCondition) {
          updatedCondition.isExactly = action.payload.isExactly
          updatedCondition.promotionDays = [...action.payload.promotionDays]
          updatedCondition.stackingId = action.payload.stackingId
        }

        return { ...state, condition: updatedCondition }
      }
    case 'PROMOTION_HOURS_UPDATE':
      return { ...state, condition: { ...state.condition, promotionHours: [...state.condition.promotionHours.filter(x => x.id !== action.payload.id), action.payload] } }

    case 'PROMOTION_HOURS_ADD':
      return { ...state, condition: { ...state.condition, promotionHours: [...state.condition.promotionHours, action.payload] } }

    case 'PROMOTION_HOURS_REMOVE':
      return { ...state, condition: { ...state.condition, promotionHours: [...(state.condition.promotionHours.filter(x => x.id !== action.payload.id))] } }

    case 'SET_PREFERRED_CUSTOMER_ID':
      return { ...state, condition: { ...state.condition, preferredCustomerId: action.payload.value } }

    case 'ADD_CUSTOMER_GROUP':
      return { ...state, condition: { ...state.condition, customerGroupsId: [...state.condition.customerGroupsId, action.payload] } }

    case 'UPDATE_CUSTOMER_GROUP':
      return { ...state, condition: { ...state.condition, customerGroupsId: [...state.condition.customerGroupsId.filter(x => x.id !== action.payload.oldCustomerGroupId.id), action.payload.newCustomerGroupId] } }

    case 'REMOVE_CUSTOMER_GROUP':
      return { ...state, condition: { ...state.condition, customerGroupsId: [...(state.condition.customerGroupsId.filter(x => x.id !== action.payload))] } }

    case 'SET_REWARD_TYPE':
      return { ...state, reward: { ...state.reward, rewardTypeId: action.payload } }

    case 'ADD_REWARD_ITEM':
      return { ...state, reward: { ...state.reward, rewardItem: [...state.reward.rewardItem, action.payload] } }

    case 'UPDATE_REWARD_ITEM':
      return { ...state, reward: { ...state.reward, rewardItem: [...state.reward.rewardItem.filter(x => x.id !== action.payload.id), action.payload] } }

    case 'REMOVE_REWARD_ITEM':
      return { ...state, reward: { ...state.reward, rewardItem: [...(state.reward.rewardItem.filter(x => x.id !== action.payload.id))] } }

    case 'SET_REWARD_QUANTITY':
      return { ...state, reward: { ...state.reward, rewardQuantity: action.payload } }

    case 'SET_REWARD_TERM':
      return { ...state, reward: { ...state.reward, rewardTerm: action.payload } }

    case 'ADD_REWARD_MODIFIER':
      {
        const rewardItem = state.reward.rewardItem.find(x => x.id === action.payload.rewardItem.id)
        if (rewardItem) {
          rewardItem.toppingsId = [...rewardItem.toppingsId, action.payload.toppingId]
          return { ...state, reward: { ...state.reward, rewardItem: [...(state.reward.rewardItem.filter(x => x.id !== action.payload.rewardItem.id)), rewardItem] } }
        } else return state
      }

    case 'REMOVE_REWARD_MODIFIER':
      {
        const rewardItem = state.reward.rewardItem.find(x => x.id === action.payload.rewardItem.id)
        if (rewardItem) {
          rewardItem.toppingsId = [...rewardItem.toppingsId.filter(x => x !== action.payload.toppingId)]
          return { ...state, reward: { ...state.reward, rewardItem: [...(state.reward.rewardItem.filter(x => x.id !== action.payload.rewardItem.id)), rewardItem] } }
        } else return state
      }

    case 'SET_REWARD_SPECIAL_PRICE':
      return { ...state, reward: { ...state.reward, specialPrice: action.payload } }

    case 'SET_REWARD_ISSUE_TERM':
      return { ...state, reward: { ...state.reward, rewardIssueTerm: action.payload } }

    case 'SET_FLAT_DISCOUNT':
      return { ...state, reward: { ...state.reward, flatDiscount: action.payload } }

    case 'SET_DISCOUNT_RATE':
      return { ...state, reward: { ...state.reward, discountRate: action.payload } }

    case 'UPDATE_PROMOTION_DAYS':
      {
        const promoDays = state.condition.promotionDays
        promoDays[action.payload.dayIndex] = action.payload.status === true ? 1 : 0
        return { ...state, condition: { ...state.condition, promotionDays: [...promoDays] } }
      }

    default:
      return state
  }
}

export default promotionInfoReducer