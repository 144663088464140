import { Ingredient } from "src/entities/ingredient/Ingredient"
import { v4 as guid } from 'uuid'

const data: Ingredient = {
    id: guid(),
    ingredientName: "",
    barcode: null,
    imagePath: null,
    unitId: 1,
    unitName: null,
    losses1: 0,
    losses2: 0,
    weight: 0,
    isActive: true
}
const ingredientReducer = (state: Ingredient = data, action) => {
    switch (action.type) {
        case 'SET_INGREDIENT':
            return {...action.payload}

        default:
            return state
    }
}

export default ingredientReducer